import React ,{Component} from 'react';
import { Navbar } from 'react-bootstrap';
class Header extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }

    render(){
        return(
            <header>
                <Navbar className="nav-bar">
                    <Navbar.Brand href="#home">
                        <img src="http://www.puertadeoro.org/wp-content/uploads/2018/02/logo.png" alt="" style={{width:193}}/>
                    </Navbar.Brand>
                        <Navbar.Toggle />
                </Navbar>
            </header>
        )
    }
}


export default Header;