import React, {Component, Fragment} from 'react';
import {Formik, Field, Form,ErrorMessage } from 'formik';
import { Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import { suppliers, categories } from '../../../api/feathersClient';

class RegisterSupplier extends Component {
    constructor(props) {
        super(props)
        this.state = {
            supplier:{
                name:'',
                city:'',
                address:'',
                person_type:'Natural',
                document_type: 'C.C',
                document_number: '',
                from_shareholder:'false',
                legal_representative:false,
                type_sector:'Publico',
                contact_name:'',
                contact_phone:'',
                contact_email:'',
                supplier_contacts:[],
                big_taxpayers:false,
                category_name: ''
            },
            category:{
                tags:[]
            },
            alert:{
                showAlert:false,
                message:'',
                variant:''
            },
            categoriesList:[],
            category_now:{
                name:'',
                tags:[]
            },
        }
    }

    componentDidMount(){
        categories.find({query:{$limit:10000000}}).then((categories)=>{
            this.setState({categoriesList: categories.data})
        })
    }

    handleChangeSelectCategory = (event, values) => {

        this.setState({category_id: event.target.value});
        categories.get(event.target.value).then((category)=>{

            this.setState({category:category, supplier:{...this.state.supplier, ...values, category_name: category.name}})

            this.setState({category_now: {...category, tags:[]}})
        })
    }

    editTags = async (tag, exists) =>{

        let newCategoryNowTags = [];

        if (exists) {
            newCategoryNowTags = this.state.category_now.tags.filter(it => it._id !== tag._id);
        } else {
            newCategoryNowTags = this.state.category_now.tags;
            newCategoryNowTags.push(tag);
        }

        this.setState({
            category_now: {
                ...this.state.category_now,
                tags: newCategoryNowTags
            }
        });
    }

    handleDismiss = () => this.setState({ alert:{showAlert: false} });

    render() {
        const { supplier, alert, categoriesList, category_now } = this.state;
        return (
            <Fragment>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-md-6 box_form">
                            <Formik
                            enableReinitialize
                            initialValues={supplier}
                            validationSchema={Yup.object().shape({
                                name: Yup.string()
                                    .required('El nombre es requerido'),
                                city: Yup.string()
                                    .required('La ciudad es requerida'),
                                address: Yup.string()
                                    .required('La dirección es requerida'),
                                document_number: Yup.string()
                                    .required('EL numero de documento es requerido'),
                                contact_name: Yup.string()
                                    .required('El nombre del contacto es requerido'),
                                contact_phone: Yup.string()
                                    .required('EL Celular del contacto es requerido'),
                                contact_email: Yup.string()
                                    .email('Correo invalido')
                                    .required('El correo del contacto es requerido')
                                
                            })}
                            onSubmit={(values, {resetForm}) => {

                                const supplier_contacts = [
                                    {   
                                        main_contact: true,
                                        contact_name: values.contact_name,
                                        contact_phone: values.contact_phone,
                                        contact_email: values.contact_email,
                                    }
                                ];

                                const data = {
                                    name: values.name,
                                    city:values.city,
                                    address: values.address,
                                    big_taxpayers: values.big_taxpayers,
                                    approval_status: 'Aprobado',
                                    document_number: values.document_number,
                                    document_type: values.document_type,
                                    from_shareholder: false,
                                    person_type:values.person_type,
                                    type_sector: values.type_sector,
                                    supplier_contacts: supplier_contacts,
                                    categories:category_now
                                }
                                suppliers.create(data).then(async (a)=>{
                                    this.setState({alert:{variant:'success', message:'Se agrego el proveedor', showAlert: true}});
                                    resetForm(values.name="");
                                }).catch((error)=>{
                                    this.setState({alert:{variant:'danger', message:error.message, showAlert: true}});
                                })
                                
                            }}
                            render={({ errors, touched, handleChange, handleSubmit, values, setFieldValue, handleReset }) => (
                                <Form name="a" onSubmit={handleSubmit}>
                                    {
                                        (alert.showAlert) && (
                                            <div>
                                               <p>Hemos recibido tu registro a nuestro portal de Proveedores.</p>
                                               <p>Por favor envíanos los siguientes datos respondiendo el correo que te acabamos de enviar</p>
                                               <ul>
                                                    <li>Cámara de Comercio</li>
                                                    <li>RUT</li>
                                                    <li>Logo en jpg o png</li>
                                               </ul>
                                               <p>En caso dado de no recibir la siguiente información en los próximos 3 días el sistema automáticamente dará de baja tu registro.</p>
                                            </div>
                                        )
                                    }
                                    {
                                        (alert.showAlert == false) && (
                                            <div>
                                                <h4 className="text-center">Formulario para el registro de proveedores</h4>
                                                <div className="form-group">
                                                    <label htmlFor="name">Razón social</label>
                                                    <input 
                                                        name="name"
                                                        type="text"
                                                        onChange={handleChange}
                                                        className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                                                        value={values.name}
                                                    />
                                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="city">Ciudad</label>
                                                    <input 
                                                        name="city"
                                                        type="text"
                                                        onChange={handleChange}
                                                        className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')}
                                                        value={values.city}
                                                    />
                                                    <ErrorMessage name="city" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="address">Dirección</label>
                                                    <input
                                                        name="address"
                                                        type="text"
                                                        className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}
                                                        onChange={handleChange}
                                                        value={values.address}
                                                    />
                                                    <ErrorMessage name="address" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleFormControlSelect1">Tipo de persona</label>
                                                    <select 
                                                        className="form-control"
                                                        name="person_type"
                                                        onChange={handleChange}
                                                        value={values.person_type}
                                                    >
                                                        <option value="Natural">Natural</option>
                                                        <option value="Juridica">Juridica</option>
                                                    </select>
                                                    <ErrorMessage name="person_type" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleFormControlSelect1">Tipo de documento</label>
                                                    <select 
                                                        className="form-control"
                                                        id="exampleFormControlSelect1"
                                                        name="document_type"
                                                        onChange={handleChange}
                                                        value={values.document_type}
                                                    >
                                                        <option value="NIT">NIT</option>
                                                        <option value="C.C">C.C</option>
                                                        <option value="C.E">C.E</option>
                                                        <option value="Pasaporte">Pasaporte</option>
                                                        <ErrorMessage name="document_type" component="div" className="invalid-feedback" />
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="email">Numero de documento</label>
                                                    <input 
                                                        name="document_number" 
                                                        type="text" 
                                                        className={'form-control' + (errors.document_number && touched.document_number ? ' is-invalid' : '')}
                                                        onChange={handleChange}
                                                        value={values.document_number}
                                                    />
                                                    <ErrorMessage name="document_number" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleFormControlSelect1">Tipo de sector</label>
                                                    <select 
                                                        className="form-control" 
                                                        name="type_sector"
                                                        onChange={handleChange}
                                                        value={values.type_sector}
                                                    >
                                                        <option value="Publico">Publico</option>
                                                        <option value="Privado">Privado</option>
                                                        <option value="Mixto">Mixto</option>
                                                        <ErrorMessage name="type_sector" component="div" className="invalid-feedback" />
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleFormControlSelect1">¿Gran contibuyente?</label>
                                                    <div className="form-group">
                                                        <label className="radio-inline">Si 
                                                            <input 
                                                                type="radio" 
                                                                aria-label="Radio button for following text input" 
                                                                name="big_taxpayers"
                                                                onChange={()=>setFieldValue('big_taxpayers',true)}
                                                                value={values.big_taxpayers}
                                                                checked={values.big_taxpayers}
                                                            /></label>
                                                            <label className="radio-inline">No 
                                                            <input 
                                                                type="radio" 
                                                                aria-label="Radio button for following text input" 
                                                                name="big_taxpayers"
                                                                onChange={()=>setFieldValue('big_taxpayers',false)}
                                                                value={values.big_taxpayers}
                                                                checked={!values.big_taxpayers}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h4>Seleccionar categoria</h4>
                                                </div>
                                                <div>
                                                    <div className="form-group">
                                                        <label for="exampleFormControlSelect1">Seleccione categoria</label>
                                                        <select 
                                                            className="form-control"
                                                            name="category_name"
                                                            value={values.category_name}
                                                            onChange={(e)=>{ this.handleChangeSelectCategory(e, values); return setFieldValue('category_name', e)  }}
                                                        >
                                                            {
                                                                (this.state.category.tags.length < 1) && (
                                                                    <option value="Null">Seleccionar</option>
                                                                )
                                                            }
                                                            {
                                                                categoriesList.map((category, i)=>{
                                                                    return(
                                                                        <option key={i} value={category._id} name={category.name}>{category.name}</option>
                                                                    )
                                                                })
                                                            }
                                                            <ErrorMessage name="category_name" component="div" className="invalid-feedback" />
                                                        </select>
                                                        <div>
                                                            {(this.state.category.tags.length > 0) && (
                                                                <h5>Seleccione los tags</h5>
                                                            )}
                                                            {
                                                                this.state.category.tags.map((tag, i)=>{
                                                                    const exists = !!this.state.category_now.tags.filter(it2 => it2._id === tag._id).length;
                                                                    return (
                                                                        <Fragment key={i}>
                                                                            <span
                                                                                name="tags"
                                                                                className={`badge badge-pill badge_tags ${exists && 'badge badge-primary'}`}
                                                                                onClick={() =>this.editTags(tag, exists)}
                                                                            >{`${tag.name} `}</span>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h3>Datos de contacto</h3>
                                                </div>
                                                    <div >
                                                        <div className="form-group">
                                                            <label for="exampleFormControlSelect1">¿Es el representante legal?</label>
                                                            <div className="form-group">
                                                                <label className="radio-inline">Si 
                                                                    <input 
                                                                        type="radio" 
                                                                        aria-label="Radio button for following text input" 
                                                                        name="legal_representative"
                                                                        onChange={()=>setFieldValue('legal_representative',true)}
                                                                        value={values.legal_representative}
                                                                        checked={values.legal_representative}
                                                                    />
                                                                </label>
                                                                <label className="radio-inline">No 
                                                                    <input 
                                                                        type="radio" 
                                                                        aria-label="Radio button for following text input" 
                                                                        name="legal_representative"
                                                                        onChange={()=>setFieldValue('legal_representative',false)}
                                                                        value={values.legal_representative}
                                                                        checked={!values.legal_representative}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name">Nombre completo</label>
                                                            <input 
                                                                name="contact_name" 
                                                                type="text" 
                                                                className={'form-control' + (errors.contact_name && touched.contact_name ? ' is-invalid' : '')}
                                                                onChange={handleChange}
                                                                value={values.contact_name}
                                                            />
                                                            <ErrorMessage name="contact_name" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name">Celular</label>
                                                            <input 
                                                                name="contact_phone" 
                                                                type="text" 
                                                                className={'form-control' + (errors.contact_phone && touched.contact_phone ? ' is-invalid' : '')}
                                                                onChange={handleChange}
                                                                value={values.contact_phone}
                                                            />
                                                            <ErrorMessage name="contact_phone" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name">Correo electronico</label>
                                                            <input 
                                                                name="contact_email" 
                                                                type="text" 
                                                                className={'form-control' + (errors.contact_email && touched.contact_email ? ' is-invalid' : '')}
                                                                onChange={handleChange}
                                                                value={values.contact_email}
                                                            />
                                                            <ErrorMessage name="contact_email" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary mr-2" >Registrarse</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Form>
                            )}
                        />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default RegisterSupplier;



