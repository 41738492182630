import React, { Component } from 'react';

class NotFound extends Component{

    render(){
        return(
            <div>
                <p>Pagina no encontrada</p>
            </div>
        )
    }
}


export default NotFound;