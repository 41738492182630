import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import S3Uploader from 'react-s3-uploader';

class App extends Component {

  state = {
    progress: null,
    name_file: ''
  };

  onUploadStart = (file, next) => {
    this.setState({name_file: file.name})
    next(file);
    // console.log('-------', file)
  }
  onSignedUrl = (...props) => {
    // console.log('signed url', props)
  }
  onUploadProgress = (progress, ...props) => {
    // console.log('up progress', progress)
    this.setState({ progress });
  }
  onUploadError = (error) => {
    // console.log('on error----', error)
    // notification.error({ message: error });
  }

  onUploadFinish = async (urls)  => {
    // console.log(urls)
    // console.log(urls.fileKey);
    const {match} = this.props;

    this.props.setUploadFiles(urls.fileKey, this.state.name_file);
  }

  render() {
    const { file = {}, match } = this.props;
    const { progress } = this.state;
    // console.log('file', file)
    if (file.name) return (
      <div className="s3Button mr-2">
        {file.name}
      </div>
    );
    return (
      <div className="mr-2">
        <label 
          htmlFor="flat-button-file"
          className="flat-button-file"
          variant="outlined"
          color="primary"
        >SUBIR ARCHIVOS</label>
        <label className="s3Button">
          <S3Uploader
            id="flat-button-file"
            signingUrl="/s3Front/sign"
            signingUrlMethod="GET"
            accept="*/*"
            s3path={`suppliers/${match.params.id}/files/`}
            preprocess={this.onUploadStart}
            onSignedUrl={this.onSignedUrl}
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={this.onUploadFinish}
            signingUrlWithCredentials={ true }      // in case when need to pass authentication credentials via CORS
            uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}  // this is the default
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
            server="https://proveedores-backend.puertadeoro.org"
            // inputRef={cmp => this.uploadInput = cmp}
            autoUpload={true}
            className="s3-uploader"
            style={{display: 'none'}}
          />
        </label>
      </div>
    );
  }
}

export default withRouter(App);
