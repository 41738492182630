import React, { Component } from 'react';
import { Card,Alert } from 'react-bootstrap';
import { UploadFiles } from '../../S3UploadFiles';
import { supplierQuote } from '../../../api/feathersClient';
import moment from 'moment';
import '../../../assets/files.css'

class PanelSupplier extends Component{
        constructor(props){
            super(props)
            this.state = {
                supplier:{},
                supplierQuote:{
                    files:[]
                },
                token:null,
                quote_id:null,
                alert:{
                    showAlert:false,
                    variant:'',
                    message:''
                }
            }
        }

    componentWillReceiveProps(nextProps){
        // console.log(nextProps,'---------------')
        this.setState({
            supplier:nextProps.dataSupplier,
            supplierQuote:nextProps.supplierQuote,
            token:nextProps.token,
            quote_id:nextProps.quote_id
        })
    }

    setUploadFiles = async (url, name) => {

        const files = JSON.parse(JSON.stringify(this.state.supplierQuote.files))

        const newFile = {
            name: name,
            path: url,
            createdAt: moment().format()
        };

        files.push(newFile);

        const params = {
            query: {
                token:this.state.token,
                quote_id: this.state.quote_id
            }
        };

        supplierQuote.patch(this.state.supplierQuote._id,{files:files},params)
        .then((response)=>{
            this.setState({supplierQuote:response,alert:{showAlert:true, message:'Archivo cargado', variant:"success"}})
        }
        ).catch((error)=>{
            this.setState({alert:{showAlert:true, message:error.message, variant:"danger"}})
        }
        )
    }

    handleDismiss = () => this.setState({ alert:{showAlert: false} });

    render(){
        const { supplier, supplierQuote, alert } = this.state;
        return(
            <div>
                <Card style={{ width: '30rem', marginTop:20 }}>
                    <Card.Body>
                        <Card.Title>{supplier.name}</Card.Title>
                        <Card.Text>
                            <Alert variant="warning">
                            *Agrega los archivos relacionados a tu cotización antes de la fecha de cierre del proceso.
                            </Alert>
                           
                        </Card.Text>
                        {   
                            <div>
                                {
                                    (supplierQuote.upload_files) && (
                                        <div>
                                            <UploadFiles setUploadFiles={this.setUploadFiles}/>
                                        </div>
                                    )
                                }
                                <div>
                                    {
                                        (alert.showAlert) && (
                                            <div>
                                                <Alert 
                                                    variant={alert.variant}
                                                    onClose={this.handleDismiss}
                                                    dismissible 
                                                >
                                                    {alert.message}
                                                </Alert>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        
                        <div>
                            <div className="container">
                                <div className="row">
                                    {/* <div className="file-box"> */}
                                        {
                                            supplierQuote.files.map((file, i)=>{
                                                return(
                                                    <div key={i} className="col-md-6 file">
                                                        <a href={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/${file.path}`} target="_blank" >
                                                            {
                                                                ['jpg','png','jpeg','gif'].includes((file.name).split('.')[1].toLowerCase()) && (
                                                                    <div className="image ">
                                                                        <img alt="image" className="img-responsive img-file" src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/${file.path}`} />
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                ['pdf','docx'].includes((file.name).split('.')[1].toLowerCase()) && (
                                                                    <div>
                                                                        <div className="icon">
                                                                            <i className="fa fa-file"></i>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                
                                                                ['xls','xlt','xls','xml','xlsx'].includes((file.name).split('.')[1].toLowerCase()) && (
                                                                    <div className="image">
                                                                        <img alt="image" class="img-responsive img-file" src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/suppliers/9/files/descarga.png`} />
                                                                    </div>
                                                                )
                                                                
                                                            }
                                                            {
                                                                ['rar','zip'].includes((file.name).split('.')[1].toLowerCase()) && (
                                                                    <div className="icon">
                                                                        <i className="fa fa-file-archive-o"></i>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                ['jpg','png','jpeg','gif','rar','zip','xls','xlt','xls','xml','xlsx','pdf','docx','jpg','png','jpeg','gif'].includes(!(file.name).split('.')[1].toLowerCase()) && (
                                                                    <div>
                                                                        <div className="icon">
                                                                            <i className="fa fa-file-o"></i>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            <div className="file-name">
                                                                {file.name}
                                                                <br></br>
                                                                <small>{moment(file.createdAt).format('YYYY-MM-DD : HH:MM')}</small>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default PanelSupplier;