import React, { Component } from 'react';
import { quotes, quotesValidate, supplierQuote } from '../../api/feathersClient';
import { Form, Button, Alert } from 'react-bootstrap';
import {PanelQuote, PanelSupplier} from './components';
import { Card } from 'react-bootstrap';
import moment from 'moment';

class Quote extends Component{
        constructor(props){
            super(props)
            this.state={
                quote:{},
                token:null,
                quote_id: null,
                token_response:null,
                supplierQuote:{},
                alert:'none'
            }
        }

    componentDidMount(){

        const { id } = this.props.match.params

        quotes.find({
            query:{
                _id:id,
                approval_status: 'Abierto',
                // end_date_process:{
                //     $gte: moment(new Date()).format()
                // }
            }
        }).then((quote)=>{
            if(quote.total > 0){
                this.setState({quote:quote.data[0]})
                // console.log('-----------------------------------------')
            }
        }).catch((error) =>{
            
            console.log('mensaje no se encontro el registro del proceso');
        });
    }

    handleChange = (event) => {
        this.setState({token: event.target.value});
      }

    vefirierData = ()  => {
        const params = {
            quote_id: this.state.quote._id,
            token: this.state.token
        };

        quotesValidate.create(params).then((response)=>{
            this.setState({token_response:response.token, quote_id:response.quote_id});

            supplierQuote.find({
                query:{
                    token:response.token,
                    'quote._id':response.quote_id
                }
            }).then((response)=>{
                this.setState({supplierQuote:response.data[0]})
            }).catch((error)=>{
                this.setState({alert:'block'})
            });
        }).catch(()=>{
            this.setState({alert:'block'})
        });
    }

    handleDismiss = () => this.setState({ alert: 'none' });

    render(){
        const { quote, token_response, supplierQuote, alert } = this.state;
        return(
            <div className="container">
                <div>
                    <div className="row">
                        <div className="col col-md-6">
                            <PanelQuote dataQuote={quote} />
                        </div>
                        {
                            ( token_response !== null) ? (
                                <div className="col col-md-6">
                                    <PanelSupplier 
                                        dataSupplier={this.state.supplierQuote.supplier} 
                                        supplierQuote={supplierQuote} 
                                        token={this.state.token_response} 
                                        quote_id={this.state.quote_id}
                                    />
                                </div>
                                
                            ) : (
                                <div>
                                    <div>
                                        <Card style={{ width: '30rem', marginTop:20 }}>
                                            <Card.Body>
                                                <Card.Title>Token de Identificación</Card.Title>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Ingresa el token que te enviamos al correo"
                                                        onChange={this.handleChange}
                                                    />
                                                </Form.Group>
                                                <Button 
                                                    variant="success"
                                                    onClick={()=>this.vefirierData()}
                                                    >Validar Token
                                                </Button>
                                                <Alert variant="danger" onClose={this.handleDismiss} dismissible style={{display:alert, marginTop:10}}>
                                                    Token de Identificación no encontrado
                                                </Alert>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Quote;