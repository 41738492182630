import React, {Component} from 'react';
import { Card } from 'react-bootstrap';
import moment from 'moment';

class PanelQuote extends Component{
    constructor(props){
    super(props)
        this.state={
            quote:{}
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({quote:nextProps.dataQuote})
    }

    render(){
        const { quote } = this.state;
        console.log(quote,'--------------------------********')
        return(
            <div>
                <Card style={{ width: '30rem', marginTop:20 }}>
                    <Card.Body>
                        <Card.Title>{quote.process_name}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">Fecha Inicio : {moment(quote.createdAt).format('YYYY-MM-DD : HH:mm')} </Card.Subtitle>
                        <Card.Subtitle className="mb-2 text-muted">Fecha Cierre : {moment(quote.end_date_process).format('YYYY-MM-DD : HH:mm')} </Card.Subtitle>
                        <Card.Subtitle className="mb-2 mt-2">
                            Descripción del Proceso:
                        </Card.Subtitle>
                        <Card.Text>
                        {quote.description}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default PanelQuote;