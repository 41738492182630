import React from 'react';
import Header from '../components/Header'
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';

//QUOTES
import { Quote } from '../components/Quote';

//NOTFOUND.
import { NotFound } from '../components/NotFound';

//REGISTER
import { RegisterSupplier } from '../components/Suppliers/components';

function App() {
  return (
    <Router>
        <div className="App">
            <Header />
            <Switch>
              <Route exact path='/process/verify/:id' component={ Quote } />
              <Route exact path='/register/supplier' component={ RegisterSupplier } />
              <Route patch="**" component={NotFound} />
            </Switch>
        </div>
    </Router>
  );
}

export default App;
