const feathers = require('@feathersjs/feathers');
const rest = require('@feathersjs/rest-client');

const app = feathers();

// Connect to a different URL
// const restClient = rest('http://localhost:3030');
const restClient = rest('https://proveedores-backend.puertadeoro.org');

// Configure an AJAX library (see below) with that client 
app.configure(restClient.fetch(window.fetch));

export const quotes = app.service('quotes');
export const quotesValidate = app.service('suppliers-quotes-validate');
export const supplierQuote = app.service('suppliers-quotes');
export const suppliers = app.service('suppliers');
export const categories = app.service('categories');